import React from "react";
import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react";
import { components } from "../slices";
import Layout from "../components/Layout";
import Content from "../components/Content";
import "../styles/page.scss";
import { ParallaxProvider } from "react-scroll-parallax";
import Contact from "../components/Contact";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import BackgroundSlides from "../components/BackgroundSlides";
import favicon from "../assets/favicon.png";

const Creation = ({ location, data }) => {
  const creation = data.prismicCreation;
  const lang = creation.lang.slice(0, 2);
  return (
    <ParallaxProvider>
      <Layout page={creation} type="page">
        <main>
          <BackgroundSlides
            lang={lang}
            location={location}
            color={creation.data.page_color}
          />
          <Content color={creation.data.page_color}>
            <div className="main-image-container">
              <GatsbyImage
                image={getImage(creation.data.main_image.localFile)}
                objectFit="COVER"
                className={`duotoned main-image`}
              />
            </div>
            <div className="creation-header">
              <div className="meta">
                <div>
                  {lang === "fr" ? "Pour" : "for"} {creation.data.client.text}
                </div>
                {creation.data.date && (
                  <div>{creation.data.date.toString().substring(0, 4)}</div>
                )}
                <div>
                  {creation.data.tags.map((item, index) => {
                    if (item.tag.document === null) return;
                    return (
                      <>
                        {item.tag.document.data.name.text}
                        {index < creation.data.tags.length - 1 && <>, </>}
                      </>
                    );
                  })}
                </div>
              </div>
              <h1>{creation.data.title.text}</h1>
            </div>
            <SliceZone slices={creation.data.body} components={components} />
          </Content>
          <Contact lang={creation.lang} />
        </main>
      </Layout>
    </ParallaxProvider>
  );
};

export default Creation;

export const Head = ({ data }) => {
  let schema = null;
  if (data.prismicCreation.data.body.find((b) => b.slice_type === "faq")) {
    const faqData = data.prismicCreation.data.body.filter(
      (b) => b.slice_type === "faq"
    )[0];
    console.log("slice_type === faq", faqData);
    if (faqData.primary.is_structured_data_seo) {
      console.log("faqData", faqData);
      let entities = [];
      schema = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: [entities],
      };

      faqData.items.map((i) =>
        entities.push({
          "@type": "Question",
          name: i.question,
          acceptedAnswer: {
            "@type": "Answer",
            text: i.answer.text,
          },
        })
      );
    }
  }
  return (
    <>
      <title>{`${data.prismicCreation.data.title.text.toLowerCase()} → the sun project`}</title>
      <meta
        name="description"
        content={data.prismicCreation.data.seo_description.text}
      />
      <meta
        name="keywords"
        content={data.prismicCreation.data.seo_keywords.text}
      />
      <meta name="author" content="the sun project" />

      <meta
        property="og:title"
        content={`${data.prismicCreation.data.title.text.toLowerCase()} → the sun project`}
      />
      <meta property="og:site_name" content="the sun project" />
      <meta
        property="og:description"
        content={data.prismicCreation.data.seo_description.text}
      />
      <meta
        property="og:image"
        content={data.prismicCreation.data.main_image.localFile.url}
      />
      <meta property="og:locale" content={data.prismicCreation.lang} />
      <meta property="og:type" content="article" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="the sun project" />
      <meta
        name="twitter:title"
        content={`${data.prismicCreation.data.title.text.toLowerCase()} → the sun project`}
      />
      <meta
        name="twitter:description"
        content={data.prismicCreation.data.seo_description.text}
      />
      <meta
        name="twitter:image"
        content={data.prismicCreation.data.main_image.localFile.url}
      />

      <link rel="icon" type="image/png" href={favicon} />
      {schema && (
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      )}
    </>
  );
};

export const pageQuery = graphql`
  query CreationBySlug($uid: String, $lang: String) {
    prismicCreation(id: { eq: $uid }, lang: { eq: $lang }) {
      lang
      uid
      type
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        title {
          text
        }
        seo_keywords {
          text
        }
        seo_description {
          text
        }
        main_image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 3000
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
            url
          }
        }
        client {
          text
        }
        date
        tags {
          tag {
            document {
              ... on PrismicTag {
                data {
                  name {
                    text
                  }
                }
              }
            }
          }
        }
        page_color
        body {
          ...CreationDataBodySectionTitle
          ...CreationDataBodyTextBox
          ...CreationDataBodyListBox
          ...CreationDataBodyImageBox
          ...CreationDataBodyEmbedBox
          ...CreationDataBodyImageGrid
          ...CreationDataBodyLogoBox
          ...CreationDataBodySlideshow
          ...CreationDataBodyTextImageBox
          ...CreationDataBodyCreationSlideshow
          ...CreationDataBodyEmbedMultiple
          ...CreationDataBodyFaq
        }
      }
    }

    allPrismicCreation(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___date, order: DESC }
    ) {
      nodes {
        lang
        uid
        alternate_languages {
          id
          type
          lang
          uid
        }
        data {
          title {
            text
          }
          main_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 3000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          page_color
          client {
            text
          }
          date
          tags {
            tag {
              uid
            }
          }
        }
        type
      }
    }
  }
`;
